@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap');

@layer base {
  .richText h1 {
    @apply text-4xl lg:text-5xl;
    @apply text-gray-700 font-extrabold;
    @apply my-8;
  }
  .richText h2 {
    @apply text-2xl lg:text-3xl;
    @apply text-gray-700 font-medium leading-none;
    @apply my-2;
  }
  .richText h3 {
    @apply text-xl;
    @apply text-gray-700 font-medium;
  }
  .richText h4 {
    @apply px-6 py-4 my-8 bg-om_cyan bg-opacity-30;
    @apply text-xl font-bold text-center text-gray-700;
  }
  .richText h5 {
    @apply px-6 py-4 my-8 border-t border-b border-om_cyan;
    @apply text-xl text-center text-gray-700;
  }
  .richText h6 {
    @apply px-6 py-4 my-8 border-l-[15px] border-r-[15px] border-om_purple bg-om_purple bg-opacity-10;
    @apply text-xl text-center text-gray-700 font-bold;
  }
  .richText hr {
    @apply h-5 border-t-0;
  }
  .richText ul, .richText ol {
    @apply pl-5;
    @apply my-3;
  }
  .richText ul li {
    @apply list-disc;
  }
  .richText ol li {
    @apply list-decimal;
  }
  .richText b {
    @apply text-slate-700;
  }
  .richText a {
    @apply text-blue-600;
  }
  .richText p a:hover {
    @apply underline;
  }
  .richText p {
    @apply my-3 leading-5;
  }
  input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }
}

body {
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.control-dots li.dot {
  background: #000 !important;
  box-shadow: none !important;
  width: 10px !important;
  height: 10px !important;
}
.control-dots li.dot.selected {
  background: #ED6C26 !important;
  box-shadow: none !important;
}
.carousel.carousel-slider .control-arrow {
  background: rgba(0,0,0,0.4) !important;

}
.carousel .carousel-status {
  opacity: 0;
}
